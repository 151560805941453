import React from "react";
import { Container } from "@mui/material";
import aboutBg from "../../../../assets/images/aboutBg.jpg";
import styled from "@emotion/styled";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: 110vh;
  width: 100%;
  background-image: url(${aboutBg});
  background-position: top;
  background-size: cover;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const AboutSection = () => {
  return (
    <>
      <SectionWraper id="about">
        <CustomGrid
          container
          fd="column"
          jc="space-between"
          p="100px 0 10px"
          p599="100px 0 0"
          sx={{ minHeight: "100vh" }}
        >
          <CustomGrid item xs={12}>
            <SectionHeading lh=".8">About us</SectionHeading>
          </CustomGrid>
          <CustomGrid item xs={12} sm={9} md={7} fd="column">
            <Paragraph lh=".8" sx={{ fontSize: { xs: "24px", sm: "30px" } }}>
              Contract:<br /><br />
            </Paragraph>
            <br />
            <Paragraph lh=".8" sx={{ fontSize: { xs: "16px", sm: "24px" } }}>
              Heeeeyyyyyyyyy, so like, you stumbled onto our N64 shindig or
              whatever. Cool, cool. We're on some mission, moons, gains, some
              Mario Kart - who really knows? <br /><br />
              Our N64s? Bruh, they're like, warriors. Worn controllers, burnt
              cartridges - they've seen some stuff, ya know? It's gently used;
              it's a pumping battleground. Green candles galore. Degen, crypto
              wizard, or just a lost potato are all welcome. Grab a controller,
              or don't.
            </Paragraph>
          </CustomGrid>
        </CustomGrid>
      </SectionWraper>
    </>
  );
};

export default AboutSection;
