import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import Features from "../../Components/Sections/S3-Features";
import MEME from "../../Components/Sections/S4-Meme";
import Community from "../../Components/Sections/S5-Community";

const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection /> 
      <AboutSection/>
      <Features/>
      <MEME/>
      <Community/>
    </PageWrapper>
  );
};

export default MainPage;
