import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import LockerMenu from "../../LockerMenu";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import heroImage from "../../../../assets/images/heroImage.png";
import bootomShade from "../../../../assets/images/bootomShade.png";
import herobg from "../../../../assets/images/herobg.png";
import heroImage1 from "../../../../assets/images/heroImage1.png";
import heroImage2 from "../../../../assets/images/heroImage2.png";
import logo from "../../../../assets/images/logo.png";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  background-image: url(${herobg});
  background-position: bottom;
  background-size: cover;

  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  margin: 0 0px !important;
  z-index: 1;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const HeroSection = () => {
  return (
    <>
      <SectionWraper id="home">
        <AbsoluiteImage 
          mw="45%"
          mw599="100%"
          src={heroImage}
          style={{
            bottom: "0",
            right: "0",
          }}
        />
        <InnerContinerWraper>
          <Link href="/">

          <AbsoluiteImage
            mw="90px"
            mw599="50px"
            src={logo}
            t599="1%"
            l599="1%"
            style={{
              top: "5%",
              left: "5%",
            }}
          />
              </Link>
          <CustomGrid container jc="start" p="100px 0 0 70px" p599="100px 0 0">
            <CustomGrid item xs={12} sm={8} ai="start" fd="column">
              <SectionHeading lh=".8" c="#FBA500" ta="left" sx={{textShadow:"0px 0px 50px #fba300a4"}}>
                A Gently Used <br />
                Nintendo 64!
              </SectionHeading>
              {/* <div style={{ display: "flex", gap: "30px", paddingTop: "60px" }}> */}
                <DataImage mw="80%" mw599="100%" src={heroImage1} />
                <DataImage mw="50%" mw599="70%" src={heroImage2} />
              {/* </div> */}
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
        <AbsoluiteImage
          src={bootomShade}
          style={{
            bottom: "0",
            right: "0",
          }}
        />
      </SectionWraper>
    </>
  );
};

export default HeroSection;
