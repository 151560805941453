import styled from "@emotion/styled";
import { Container, Link } from "@mui/material";
import React from "react";
import comunityBg from "../../../../assets/images/comunityBg.jpg";
import Princess from "../../../../assets/images/Princess.png";
import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: 50vh;
  width: 100%;
  position: relative;
  background-image: url(${comunityBg});
  background-position: top;
  background-size: cover;
  z-index: 0;
  ::after {
    content: "";
    background-color: #000000be;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
  }
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const Community = () => {
  return (
    <>
      <SectionWraper id="about">
        <AbsoluiteImage
          mw="21%"
          mw599="40%"
          src={Princess}
          style={{
            bottom: "-150px",
            right: "2%",
          }}
        />
        <CustomGrid container fd="column" jc="center" p="50px 0">
          <CustomGrid item xs={12}>
            <SectionHeading lh=".8">Contact Us</SectionHeading>
          </CustomGrid>
          <CustomGrid item xs={12} sm={9} md={7} fd="column">
            <br />
            <Paragraph lh=".8" sx={{ fontSize: { xs: "24px", sm: "30px" } }}>
              Princess Peach: +1-800-PEACHYTITS
            </Paragraph>
            <Paragraph lh=".8" sx={{ fontSize: { xs: "16px", sm: "22px" } }}>
              Note: Any attempts to receive sexual favors from Peach in exchange
              for $USEDN64ETH will have all your funds liquidated.
            </Paragraph>
            <div
              style={{
                display: "flex",
                gap: "30px",
                paddingTop: "60px",
                position: "relative",
                zIndex: "1",
              }}
            >
              <Link href="https://t.me/USEDN64ETH" target="blank">
                <DataImage mw="100px" src={tg} />
              </Link>
              <Link href="https://twitter.com/USEDN64ETH" target="blank">
                <DataImage mw="100px" src={x} />
              </Link>
            </div>
          </CustomGrid>
        </CustomGrid>
      </SectionWraper>
    </>
  );
};

export default Community;
