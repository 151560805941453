import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import memeBg from "../../../../assets/images/memeBg.jpg";
import bootomShade from "../../../../assets/images/bootomShade.png";
import topShade from "../../../../assets/images/topShade.png";
import Image1 from "../../../../assets/images/Image1.png";
import Image2 from "../../../../assets/images/Image2.png";
import Image3 from "../../../../assets/images/Image3.png";
import Image4 from "../../../../assets/images/Image4.png";
import Image5 from "../../../../assets/images/Image5.png";
import Image6 from "../../../../assets/images/Image6.png";
import Image7 from "../../../../assets/images/Image7.png";
import Image8 from "../../../../assets/images/Image8.png";
import video9 from "../../../../assets/images/video9.mp4";
import Image10 from "../../../../assets/images/Image10.png";
import Image11 from "../../../../assets/images/Image11.png";
import Image12 from "../../../../assets/images/Image12.png";
import Image13 from "../../../../assets/images/Image13.png";
import Image14 from "../../../../assets/images/Image14.png";
import video15 from "../../../../assets/images/video15.mp4";
import video16 from "../../../../assets/images/video16.mp4";
import Image17 from "../../../../assets/images/Image17.png";
import video18 from "../../../../assets/images/video18.mp4";
import Image19 from "../../../../assets/images/Image19.png";
import video20 from "../../../../assets/images/video20.mp4";
import Image21 from "../../../../assets/images/Image21.png";
import Image22 from "../../../../assets/images/Image22.png";
import Image23 from "../../../../assets/images/Image23.png";
import video24 from "../../../../assets/images/video24.mp4";
import video25 from "../../../../assets/images/video25.mp4";
import video26 from "../../../../assets/images/video26.mp4";
import video27 from "../../../../assets/images/video27.mp4";
import Image28 from "../../../../assets/images/Image28.png";
import Image29 from "../../../../assets/images/Image29.png";

const SectionWraper = styled.div`
  /* overflow: hidden; */
  min-height: 5000px;
  width: 100%;
  background-image: url(${memeBg});
  background-position: top;
  background-size: contain;
  position: relative;
  z-index: 0;
  ::after {
    content: "";
    background-color: #000000a7;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
  }
  @media (min-width: 1499px) {
    min-height: 6500px;
  }
  @media (max-width: 899px) {
    min-height: 4800px;
  }
  @media (max-width: 599px) {
    min-height: 2700px;
  }
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => props.t};
  left: ${(props) => props.l};
  /* transform: ${(props) =>
    props.tran ? props.tran : "translate(-50%,0%)"}; */
  max-width: ${(props) => (props.mw ? props.mw : "25%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  z-index: ${(props) => (props.zi )};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (min-width: 1499px) {
    top: ${(props) => props.t1499};
    left: ${(props) => props.l1499};
  }
  @media (max-width: 1099px) {
    max-width: ${(props) => props.mw1099};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    top: ${(props) => props.t599};
    left: ${(props) => props.l599};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const AbsoluiteVideo = styled.video`
  width: 100%;
  position: absolute;
  top: ${(props) => props.t};
  left: ${(props) => props.l};
  /* transform: ${(props) =>
    props.tran ? props.tran : "translate(-50%,0%)"}; */
  max-width: ${(props) => (props.mw ? props.mw : "25%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  z-index: ${(props) => (props.zi )};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */ 
  @media (min-width: 1499px) {
    top: ${(props) => props.t1499};
    left: ${(props) => props.l1499};
  }
  @media (max-width: 1099px) {
    max-width: ${(props) => props.mw1099};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    top: ${(props) => props.t599};
    left: ${(props) => props.l599};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;

const MEME = () => {
  return (
    <>
      <SectionWraper id="meme">
        <AbsoluiteImage
          mw="100%"
          src={topShade}
          style={{
            top: "0",
            left: "0",
          }}
        />
        <AbsoluiteImage
          mw="100%"
          zi="1"
          src={bootomShade}
          style={{
            bottom: "0",
            right: "0",
          }}
        />
        <AbsoluiteImage mw="20%" mw599="40%" mw1099="30%" src={Image1} t1499="" t="100px" l="10%" l599="3%"/>
        <AbsoluiteImage mw599="40%" mw1099="30%" src={Image2} t1499="" t="0%" l="55%" l599="40%"/>
        <AbsoluiteImage mw="10%" mw599="20%" mw1099="15%" src={Image3} t1499="" t="100px" l="85%" l599="75%" />
        <AbsoluiteImage mw="15%" mw599="30%" mw1099="20%" src={Image4} t1499="440px" t="340px" t599="300px" l="10%" l599="3%" />
        <AbsoluiteImage mw="10%" mw599="30%" mw1099="20%" src={Image5} t1499="510px" t="410px" t599="350px" l="24%" l599="30%"/>
        <AbsoluiteImage mw="25%" mw599="40%" mw1099="30%" src={Image6} t1499="250px" t="150px"  l="40%"  zi="1" />
        <AbsoluiteImage mw="28%" mw599="40%" mw1099="30%" src={Image7} t1499="340px" t="240px" l="62%"  />
        <AbsoluiteImage mw="10%" mw599="40%" mw1099="15%" src={Image8} t1499="650px" t="550px" l="35%"   t599="500px"  l599="20%"  zi="3"/>
        <AbsoluiteVideo muted autoPlay loop mw="25%" mw599="40%" mw1099="35%" src={video9} t1499="680px" t="480px" l="44%" t599="400px"  l599="60%" />
        <AbsoluiteImage mw="10%" mw599="30%" mw1099="15%" src={Image10} t1499="850px" t="550px" l="80%"  l599="65%" />
        <AbsoluiteImage mw="10%" mw599="20%" mw1099="15%" src={Image11} t1499="900px" t="650px" l="10%" l599="3%"  zi="2"/>
        <AbsoluiteImage mw="25%" mw599="30%" mw1099="30%" src={Image12} t1499="900px" t="650px" l="15%" l599="25%"  zi="1"/>
        <AbsoluiteImage mw="42%" mw599="50%" mw1099="50%" src={Image13} t1499="950px" t="700px" l="40%" l599="45%"  />
        <AbsoluiteImage mw="35%" mw599="45%" mw1099="40%" src={Image14} t1499="1790px" t="1050px" t599="800px"   l="10%" l599="3%" zi="1" />
        <AbsoluiteVideo muted autoPlay loop mw="40%" mw599="50%" mw1099="45%" src={video15} t1499="1400px" t="950px"  t599="850px"  l="50%"  />
        <AbsoluiteVideo muted autoPlay loop mw="40%" mw599="55%" mw1099="45%" src={video16} t1499="1400px" t="1350px"  t599="980px"  l="12%" l599="3%"  />
        <AbsoluiteImage mw="20%" mw599="40%" mw1099="30%" src={Image17} t1499="1700px" t="1400px"  t599="1050px" l="55%"  />
        <AbsoluiteVideo muted autoPlay loop mw="23%" mw599="40%" mw1099="24%" src={video18} t1499="1900px" t="1700px"  t599="1120px" l="25%" l599="3%" />
        <AbsoluiteImage mw="23%" mw599="30%" mw1099="23%" src={Image19} t1499="1980px" t="1680px" l="52%" l599="38%" t599="1200px"/>
        <AbsoluiteVideo muted autoPlay loop mw="22%" mw599="30%" mw1099="20%" src={video20} t1499="1900px" t="1680px" t599="1200px" l="77%" l599="68%"  />
        <AbsoluiteImage mw="25%" mw599="40%" mw1099="30%" src={Image8} t1499="2250px" t="1850px" l="10%" l599="3%" t599="1350px"  zi="3"/>
        <AbsoluiteImage mw="10%" mw599="23%" mw1099="15%" src={Image12} t1499="2300px" t="1900px" l="40%"  t599="1400px"    zi="1"/>
        <AbsoluiteImage mw="25%" mw599="40%" mw1099="30%" src={Image21} t1499="2350px" t="2050px" l="33%"  t599="1500px"   />
        <AbsoluiteImage mw="20%" mw599="40%" mw1099="30%" src={Image22} t1499="2450px" t="2050px" l="65%"  t599="1500px" zi="3" />
        <AbsoluiteImage mw="80%" mw599="85%" mw1099="85%" src={Image23} t1499="2750px" t="2350px" l="5%"  t599="1650px" zi="1" />
        <AbsoluiteVideo muted autoPlay loop mw="35%" mw599="40%" mw1099="35%" src={video24} t1499="2800px" t="2300px"  t599="1650px" l="55%"  />
        <AbsoluiteVideo muted autoPlay loop mw="28%" mw599="38%" mw1099="33%" src={video25} t1499="3850px" t="3120px"  t599="1950px" l="5%"  zi="3"/>
        <AbsoluiteVideo muted autoPlay loop mw="40%" mw599="50%" mw1099="50%" src={video26} t1499="3750px" t="3000px" t599="1900px"  l="30%"  />
        <AbsoluiteVideo muted autoPlay loop mw="30%" mw599="37%" mw1099="33%" src={video27} t1499="3850px" t="3150px" t599="1950px"  l="64%"  zi="3"/>
        <AbsoluiteImage mw="90%" mw599="90%" mw1099="90%" src={Image28} t1499="4250px" t="3450px" l="5%" t599="2100px" zi="2" />
        <AbsoluiteImage mw="75%" mw599="80%" mw1099="80%" src={Image29} t1499="5250px" t="4150px" l="2%" t599="2350px"   />
      </SectionWraper>
    </>
  );
};

export default MEME;
