import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import featureBg from "../../../../assets/images/featureBg.jpg";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: 110vh;
  width: 100%;
  background-image: url(${featureBg});
  background-position: top;
  background-size: cover;
  
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const Features = () => {
  return (
    <>
      <SectionWraper id="features">
        <CustomGrid
          container
          fd="column"
          jc="end"
          p="100px 0 10px"
          p599="100px 0 0"
          sx={{ minHeight: "100vh" }}
        >
          <CustomGrid item xs={12}>
            <SectionHeading lh=".8">Nintendo Universe</SectionHeading>
          </CustomGrid>
        </CustomGrid>
      </SectionWraper>
    </>
  );
};

export default Features;
